import React, { FC, useState } from 'react';

// redux
import { useSelector } from 'react-redux';
import { Store } from '../../../../redux/root';

// components
import { EditPlaceholderDialog } from '../EditPlaceholderDialog';

// icons
import { ReactComponent as EditSvg } from 'assets/image/icons/edit.svg';
import AvatarCell from '../../../../shared/components/Table/CellRenderers/AvatarCell';

// types
import { PlaceholderResponse } from '@joc/api-gateway';

// styles
import styles from './PlaceholdersTable.module.scss';

export const PlaceholdersTable: FC = () => {
	const [placeholderToEdit, setPlaceholderToEdit] = useState<PlaceholderResponse>();
	const placeholderList = useSelector((store: Store) => store.placeholdersRedux.records);

	const editPlaceholder = (placeholder: PlaceholderResponse) => {
		setPlaceholderToEdit(placeholder);
	};

	const closeEditPlaceholder = () => {
		setPlaceholderToEdit(undefined);
	};

	const getPlainTextFromHtml = (html: string) => {
		const elem = document.createElement('div');
		elem.innerHTML = html;

		return elem.innerText;
	};

	return (
		<>
			<table className={styles.placeholdersTable}>
				<thead>
					<tr>
						<th>Photo</th>
						<th className={styles.placeholdersTable__name}>Placeholder name</th>
						<th className={styles.placeholdersTable__description}>Description</th>
						<th aria-label="Actions" />
					</tr>
				</thead>
				<tbody>
					{(placeholderList.length &&
						placeholderList
							.sort((a, b) => a.id - b.id)
							.map((placeholder, index) => (
								<tr key={index}>
									<td align="center">
										<AvatarCell imagePath={placeholder.imagePath} />
									</td>
									<td className={styles.placeholdersTable__name} title={placeholder.name}>
										<span>{placeholder.name}</span>
									</td>
									<td
										className={styles.placeholdersTable__description}
										title={getPlainTextFromHtml(placeholder.description)}
									>
										<span>
											<div dangerouslySetInnerHTML={{ __html: placeholder.description }} />
										</span>
									</td>
									<td align="center">
										<EditSvg
											onClick={() => editPlaceholder(placeholder)}
											className={styles['placeholdersTable__edit-icon']}
											title="Edit placeholder"
										/>
									</td>
								</tr>
							))) || <></>}
				</tbody>
			</table>
			{(placeholderToEdit && (
				<EditPlaceholderDialog placeholderToEdit={placeholderToEdit} onClose={closeEditPlaceholder} />
			)) || <></>}
		</>
	);
};
