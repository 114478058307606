import React, { FC, useCallback } from 'react';
import { Field, FieldProps, Form, Formik, FormikProps } from 'formik';

// redux
import { useDispatch } from 'react-redux';
import { updatePlaceholders } from '../../../../redux/placeholders-service/actions';

// constants & helpers
import { MAX_DESCRIPTION_LENGTH, MAX_TITLE_LENGTH, placeholderValidation } from './helpers';

// components
import ButtonCross from '../../../../shared/components/Buttons/ButtonCross';
import InputText from '../../../../shared/components/FormInputs/InputText';
import ButtonDefault from '../../../../shared/components/Buttons/ButtonsDefault';
import PlaceholderImageField from './PlaceholderImageField';
import DialogStyled from './DialogStyled';

// types
import { PlaceholderResponse } from '@joc/api-gateway';
import { IPlaceholderRequest } from '@joc/api-gateway/lib/api-client';

// styles
import cx from 'classnames';
import styles from './EditPlaceholderDialog.module.scss';
import InputErrorHint from '../../../../shared/components/InputErrorHint';
import Editor from '../../../../shared/components/Editor';

type EditPlaceholderDialogProps = {
	placeholderToEdit: PlaceholderResponse;
	onClose: () => void;
};

export const EditPlaceholderDialog: FC<EditPlaceholderDialogProps> = ({ placeholderToEdit, onClose }) => {
	const closeDialog = () => onClose();
	const dispatch = useDispatch();

	const submitClickHandler = (values: PlaceholderResponse) => {
		const body = document.createElement('body');
		body.innerHTML = values.description;

		dispatch(
			updatePlaceholders(values.id, {
				name: values.name.trim(),
				description: body.outerHTML,
				imagePath: values.imagePath,
			})
		);
		onClose();
	};

	const getPlainTextFromHtmlString = (html: string) => {
		const elem = document.createElement('div');
		elem.innerHTML = html;
		return elem.innerText;
	};

	return (
		<DialogStyled open={!!placeholderToEdit} maxWidth="xl">
			<section className={styles.dialog}>
				<div className={styles.dialog__header}>
					<ButtonCross clickHandler={closeDialog} parentClassName={styles.dialog__header_button} />
				</div>
				<Formik
					initialValues={placeholderToEdit}
					validationSchema={placeholderValidation}
					onSubmit={submitClickHandler}
					className={styles.dialog__form}
				>
					{({ setFieldValue, dirty, isValid, errors, touched }: FormikProps<PlaceholderResponse>) => (
						<Form className={styles.dialog__form}>
							<span className={styles.dialog__form_title}>Edit Placeholder</span>
							<PlaceholderImageField name="imagePath" label="Photo" />
							<Field name="name">
								{({ field }: FieldProps<string, IPlaceholderRequest>) => (
									<div className={styles.dialog__form_item}>
										<label className={styles.dialog__form_label} htmlFor="name">
											Plaseholder name<span className={styles.dialog__form_required}>*</span>
										</label>
										<InputText
											id="name"
											field={field}
											placeholder="Text"
											setFieldValue={setFieldValue}
										/>

										{(errors.name && touched.name && (
											<InputErrorHint errorText={errors.name} disableMargin={true} />
										)) || (
											<span
												className={cx(styles.dialog__form_limit, {
													[styles.dialog__form_limit_error]:
														field.value.length > MAX_TITLE_LENGTH,
												})}
											>
												Maximum characters: {field.value.length}/{MAX_TITLE_LENGTH}
											</span>
										)}
									</div>
								)}
							</Field>
							<Field name="description">
								{({ form, field }: FieldProps<string, IPlaceholderRequest>) => (
									<div className={styles.dialog__form_item}>
										<label className={styles.dialog__form_label} htmlFor="description">
											Description<span className={styles.dialog__form_required}>*</span>
										</label>
										<Editor
											fieldValue={field.value}
											onChange={value => form.setFieldValue('description', value)}
											onBlur={() => form.setFieldTouched(field.name, true)}
											placeholder="Description"
											isShowToolbar={false}
										/>
										{(errors.description && touched.description && (
											<InputErrorHint errorText={errors.description} disableMargin={true} />
										)) || (
											<span
												className={cx(styles.dialog__form_limit, {
													[styles.dialog__form_limit_error]:
														getPlainTextFromHtmlString(field.value).length >
														MAX_DESCRIPTION_LENGTH,
												})}
											>
												Maximum characters: {getPlainTextFromHtmlString(field.value).length}/
												{MAX_DESCRIPTION_LENGTH}
											</span>
										)}
									</div>
								)}
							</Field>
							<div className={styles.dialog__footer}>
								<ButtonDefault
									classList={['secondary', 'lg']}
									parentClassName={styles.dialog__footer_button}
									title="Cancel"
									clickHandler={closeDialog}
								/>
								<ButtonDefault
									submitType={true}
									classList={['primary', 'lg']}
									parentClassName={cx(styles.dialog__footer_button, {
										[styles.disable_submit]: !dirty || !isValid,
									})}
									title="Save"
									disabled={!dirty || !isValid}
								/>
							</div>
						</Form>
					)}
				</Formik>
			</section>
		</DialogStyled>
	);
};
